import React from 'react';
import {
  Box,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Layout from '../components/layout';

const styling = (theme) => ({
  about: {
    margin: 'auto',
    maxWidth: 600,
    padding: 16,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    textAlign: 'justify',
    minHeight: "calc(100vh - 190px)",
  },
  header: {
    textAlign: 'center',
  },
});

const AboutUs = () => {
  // Styling/Theming
  const classes = makeStyles(styling)();
  
  return (
    <Layout title="About us">
      <Box className={classes.about}>
        <Typography className={classes.header} variant="h1">About us</Typography>
        <Typography className='about' variant="body1">
          At Blue Rondo Games, we craft easily accessible and strategically rich titles that put fresh spins on classic game genres. We are a black-owned game company based in Southern California founded by Rick Gardner in 2021. We are a team of designers and creatives brought together by a genuine love for games and community. Our team consists of graduate and undergraduate students with a strong passion for game design. We’ve built a community centered around working hard and playing hard, with an emphasis on learning at every step. Our company also serves as a platform for young artists and creatives to build skills and launch aspiring careers. Our first title, Crabs in a Bucket was successfully funded on Kickstarter and is now available for pre-order at our shop. <Link href="https://shop.bluerondogames.com" color="secondary">Get it here!</Link>
        </Typography>
      </Box>
    </Layout>
  );
}

export default AboutUs;
